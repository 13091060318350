import React, { useState } from "react";
import PrivacyLinks from "../Component/PrivacyLinks";
import { NavLink as Link } from "react-router-dom";
import { setUserSession } from "../Utils/Common";
import ApiConnect from "../Utils/ApiConnect";
import AnimatedPage from "../Component/AnimatedPage";
export default function UserVerification(props) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [verifyBtn, setVerifyBtn] = useState(false);
  const triallink = sessionStorage.getItem("trialLink");
  const registerContact = sessionStorage.getItem("userid");
  const handleSubmit = (e) => {
    if (e.target.value.length === 6) {
      e.preventDefault();
      setError(null);
      setLoading(true);
      var formData = new FormData();
      formData.append("otp", e.target.value);
      formData.append("contact", registerContact);
      ApiConnect({
        method: "post",
        url: "/Auth/OTPVerify",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          setLoading(false);
          if (response.data.status === 200) {
            setUserSession(response.data.token, response.data.id);
            if (triallink) {
              setVerifyBtn(true);
            } else {
              props.history.push("/Home");
            }
          } else if (response.data.status === 204) {
            setError(response.data.message);
          } else {
            setError(response.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          setError("Something Went Wrong, please Try Again.");
          console.log(error);
        });
    }
  };
  return (
    <AnimatedPage>
      {/* <div>
        <h6 className="mt-4 text-left text-base font-bold tracking-tight text-black dark:text-blackText">
          Contact Verification
        </h6>
      </div> */}
      <form className="space-y-4" method="GET" action={triallink}>
        <div className="-space-y-px rounded-md shadow-sm">
          <p className="error">{error}</p>
          <div>
            <label htmlFor="contact" className="p-1">
              OTP{" "}
            </label>
            <input
              type="number"
              autoComplete="number"
              required
              className="relative block w-full rounded border border-gray-300  placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 "
              placeholder="One Time Password"
              name="userotp"
              onChange={(e) => handleSubmit(e)}
              min="6"
              max="8"
            />
            {/* <div className="flex flex-row items-center justify-between ">
              <div className="w-12 h-12 ">
                <input
                  className="relative block w-full rounded-lg border bg-transparent border-gray-300  placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 "
                  type="text"
                  name=""
                  tabIndex="1"
                  maxLength="1"
                />
              </div>
              <div className="w-12 h-12 ">
                <input
                  className="relative block w-full rounded-lg border bg-transparent border-gray-300  placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 "
                  type="text"
                  name=""
                  tabIndex="2"
                  maxLength="1"
                />
              </div>
              <div className="w-12 h-12 ">
                <input
                  className="relative block w-full rounded-lg border bg-transparent border-gray-300  placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 "
                  type="text"
                  name=""
                  tabIndex="3"
                  maxLength="1"
                />
              </div>
              <div className="w-12 h-12 ">
                <input
                  className="relative block w-full rounded-lg border bg-transparent border-gray-300  placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 "
                  type="text"
                  name=""
                  tabIndex="4"
                  maxLength="1"
                />
              </div>
              <div className="w-12 h-12 ">
                <input
                  className="relative block w-full rounded-lg border bg-transparent border-gray-300  placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 "
                  type="text"
                  name=""
                  tabIndex="5"
                  maxLength="1"
                />
              </div>
              <div className="w-12 h-12 ">
                <input
                  className="relative block w-full rounded-lg border bg-transparent border-gray-300  placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 "
                  type="text"
                  name=""
                  tabIndex="6"
                  maxLength="1"
                />
              </div>
            </div> */}
          </div>
        </div>

        {!verifyBtn ? (
          <button
            disabled={"disable"}
            className="submitButton group relative flex w-full justify-center rounded-lg border border-transparent bg-primary py-3 px-4 text-sm font-medium text-white  dark:border-0  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              {/* Heroicon name: mini/lock-closed */}
              <span className="material-icons-outlined">pin</span>
            </span>
            {loading ? "Loading...." : "Verify"}
          </button>
        ) : (
          <a
            href={triallink}
            className=" submitButton group relative flex w-full justify-center rounded-md border border-transparent bg-primary py-3 px-4 text-sm font-medium text-white  dark:border-0  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              {/* Heroicon name: mini/lock-closed */}
              <span className="material-icons-outlined">pin</span>
            </span>
            {loading ? "Loading...." : "Verify Now"}
          </a>
        )}
      </form>
      <PrivacyLinks />
      <br />
      <p className="text-center">
        Don't have Account ?
        <b>
          {" "}
          <Link to="/Singup">Sing up</Link>
        </b>
      </p>
    </AnimatedPage>
  );
}
