import { motion } from "framer-motion";
import { duration } from "moment";

const brandVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    rotate: -20,
    fill: "rgba(255, 255, 255, 0)",
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    rotate: 0,
    fill: "rgba(255, 255, 255, 1)",
  },
};
const AnimatedLogo = () => {
  return (
    <motion.svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="98"
      viewBox="0 0 72 50.16"
      // viewBox="0 0 504 50.6"
      // viewBox="0 0 72 50.16"
      // xml:space="preserve"
    >
      <motion.path
        variants={{
          hidden: {
            opacity: 0,
            pathLength: 0,
            rotate: -20,
            fill: "#395dab",
          },
          visible: {
            opacity: 1,
            pathLength: 1,
            rotate: 0,
            fill: "#395dab",
          },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          default: {
            duration: 0.6,
            ease: "easeInOut",
          },
          rotate: {
            type: "spring",
            duration: 1,
            ease: [1, 0, 0.8, 1],
          },
          fill: {
            delay: 0.6,
            duration: 1,
            ease: [1, 0, 0.8, 1],
          },
        }}
        d="M69.25 25.08C69.25 37.5 54.33 47.6 36 47.6S2.75 37.5 2.75 25.08 17.67 2.56 36 2.56s33.25 10.11 33.25 22.52z"
        // style="fill:#395dab"
      />
      <motion.path
        variants={{
          hidden: {
            pathLength: 0,
            fill: "rgba(240, 78, 48, 0)",
          },
          visible: {
            pathLength: 1,
            fill: "rgba(240, 78, 48, 1)",
          },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 1, ease: "easeInOut" },
          fill: { duration: 1, ease: [1, 0, 0.8, 1] },
        }}
        d="M60.85 7.56C54.2 2.99 45.37.47 36 .47S17.81 2.99 11.15 7.56C4.38 12.21.65 18.44.65 25.09s3.73 12.87 10.5 17.53c6.65 4.57 15.47 7.09 24.85 7.09s18.2-2.52 24.85-7.09c6.77-4.65 10.5-10.88 10.5-17.53s-3.73-12.88-10.5-17.53zM36 47.6c-18.33 0-33.25-10.1-33.25-22.52S17.67 2.56 36 2.56s33.25 10.1 33.25 22.52S54.33 47.6 36 47.6z"
        // style="fill:#f04e30"
      />
      <g>
        <motion.path
          variants={{
            hidden: {
              opacity: 0,
              pathLength: 0,
              rotate: -20,
              fill: "#fff",
            },
            visible: {
              opacity: 1,
              pathLength: 1,
              rotate: 0,
              fill: "#fff",
            },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            default: {
              duration: 0.6,
              ease: "easeInOut",
            },
            rotate: {
              type: "spring",
              duration: 1,
              ease: [1, 0, 0.8, 1],
            },
            fill: {
              delay: 0.6,
              duration: 1,
              ease: [1, 0, 0.8, 1],
            },
          }}
          className="st2"
          d="m38.44 38.66.39.75H30.54l.86-1.66V29.3l-4.61-7.62v15.87l1 1.94h-7.6l.9-1.74-.03-25.01-1.18-1.89h6.41l5.51 9.48v-7.75l-1.31-1.92h8.71l-.87 1.13c-.31.21-.61.45-.89.73-2.3 2.24-3.71 6.7-3.78 11.94-.07 5.31 1.31 10.21 3.71 13.1.33.39.69.77 1.07 1.1z"
        />
        <motion.path
          variants={{
            hidden: {
              opacity: 0,
              pathLength: 0,
              rotate: -20,
              fill: "#fff",
            },
            visible: {
              opacity: 1,
              pathLength: 1,
              rotate: 0,
              fill: "#fff",
            },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            default: {
              duration: 0.6,
              ease: "easeInOut",
            },
            rotate: {
              type: "spring",
              duration: 1,
              ease: [1, 0, 0.8, 1],
            },
            fill: {
              delay: 0.6,
              duration: 1,
              ease: [1, 0, 0.8, 1],
            },
          }}
          className="st2"
          d="m52.13 22.69-.91 1.76v14.16c-2.64 1.29-5.84 1.89-9.49.78-1.43-.44-2.63-1.31-3.61-2.5-4.9-5.89-4.38-19.39 0-23.67.24-.23.49-.44.75-.62 3.47-2.34 6.84-2.23 9.07-1.72.14.02.34.04.57.04.6 0 1.42-.13 2.06-.73v7.63s-2.05-2.9-6.02-1.75c-4.87 1.41-6.11 18.15 1.11 18.71V24.59l-.98-1.9h7.45z"
        />
      </g>
    </motion.svg>
  );
};

export default AnimatedLogo;
