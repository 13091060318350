import React from "react";
import { useLocation } from "react-router-dom";
import AnimatedPage from "../Component/AnimatedPage";
export default function Error() {
  const search = useLocation().search;
  const linklogin = new URLSearchParams(search).get("link-login");
  const errorMessage = new URLSearchParams(search).get("error");
  return (
    <AnimatedPage>
      <div className="text-center">
        <h1 className="text-lg font-medium">Error</h1>
      </div>
    </AnimatedPage>
  );
}
