export const getUser = () => {
  const userStr = sessionStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

export const getToken = () => {
  return sessionStorage.getItem("token") || null;
};
export const getUserId = () => {
  return sessionStorage.getItem("userid") || null;
};
// export const setUserId = (user) =>{
//     sessionStorage.setItem('userid',user);
// }
export const setUserSession = (token, user, name = false) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("userid", user);
  sessionStorage.setItem("name", name);
};

export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("userid");
};
