import React, { useEffect, useState } from "react";
import ToggleTheme from "./ToggleTheme";
import logo from "../assets/logo/ng.svg";
import { NavLink as Link, useLocation } from "react-router-dom";
import AnimatedLogo from "./AnimateLogo";
export default function Header() {
  const company = "Narayani Bakery Cafe";
  const { pathname } = useLocation();
  const search = useLocation().search;
  const linkLoginOnly = new URLSearchParams(search).get("link-login-only");
  const linkOrigEsc = new URLSearchParams(search).get("link-orig-esc");
  const fonepayUserLink = `${linkLoginOnly}?dst=${linkOrigEsc}&username=fonepay`;
  const [TapCount, SetTapCount] = useState(0);
  const [headerMsg, setHeaderMsg] = useState("Glad to see you !");
  const [headerTitle, setHeaderTitle] = useState("Welcome,");
  function fonepayLink() {
    if (TapCount === 5 && pathname.toUpperCase() === "/LOGIN") {
      window.location.href = fonepayUserLink;
    } else {
      SetTapCount(TapCount + 1);
    }
  }
  useEffect(() => {
    switch (pathname.toUpperCase()) {
      case "/":
        setHeaderTitle("Welcome");
        setHeaderMsg("Glad to see you !");
        break;
      case "/SINGUP":
        setHeaderTitle("Create Account");
        setHeaderMsg("to get started now !");
        break;
      case "/OTPVERIFICATION":
        setHeaderTitle("OTP Verification");
        setHeaderMsg(
          `Enter the OTP send to <br/>${sessionStorage.getItem(
            "userid"
          )}  <br/><small>
                Please check your mobile inbox
              </small>`
        );
        break;
      case "/STATUS":
        setHeaderTitle("Connection Status");
        setHeaderMsg(`Hi,  ${sessionStorage.getItem("name")}!`);
        break;
    }
  }, [pathname]);
  return (
    <div>
      {pathname !== "/" &&
      pathname.toUpperCase() !== "/LOGIN" &&
      pathname.toUpperCase() !== "/STATUS" ? (
        <Link
          to="/"
          className="left-0 m-2 absolute w-10 h-10 rounded-full p-2 bg-primary  text-white"
        >
          <span className="material-icons">arrow_back</span>
        </Link>
      ) : (
        ""
      )}

      {/* <ToggleTheme /> */}
      <div className="items-center justify-center pt-20 pb-10">
        {/* <img
          onClick={() => fonepayLink()}
          src={logo}
          className=" mx-auto "
          width="140"
          alt={company}
        /> */}
        <div
          className="flex justify-center items-center "
          onClick={() => fonepayLink()}
        >
          <AnimatedLogo />
        </div>
        <h3 className="text-center text-2xl font-semibold pt-5">
          {headerTitle}
        </h3>
        <h5
          className="text-center text-base font-light py-2"
          dangerouslySetInnerHTML={{ __html: headerMsg }}
        ></h5>
      </div>
    </div>
  );
}
