import React from "react";

import Footer from "./Component/Footer";
import Header from "./Component/Header";
import NgLoading from "./Component/NgLoading";
import Login from "./Page/Login";
import Signup from "./Page/Signup";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import PrivacyPolicy from "./Page/PrivacyPolicy";
import TermsAndConditions from "./Page/TermsAndConditions";
import Ads from "./Component/Ads";
import Status from "./Page/Status";
import UserVerification from "./Page/UserVerification";
import Error from "./Page/Error";
function App() {
  return (
    <BrowserRouter>
      <NgLoading />
      <Header />
      <main className="md:flex items-center justify-center py px-5 sm:px-6 lg:px-8  ">
        <div className="space-y-1 md:w-1/2 ">
          <Routes>
            <Route path="/" exact element={<Login />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/alogin" component={<Login />} />
            <Route path="/rlogin" component={<Login />} />
            <Route path="/Signup" element={<Signup />} />
            <Route path="/Status" element={<Status />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route
              path="/TermsAndConditions"
              element={<TermsAndConditions />}
            />
            <Route path="/OTPVerification" element={<UserVerification />} />
            <Route path="/Advertisement" element={<Ads />} />
            <Route path="/Error" element={<Error />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </div>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
