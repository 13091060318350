import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/index.css";
import App from "./App";
import "material-icons/iconfont/material-icons.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div className="bg-gradient-to-b from-primary-500 to-primary-300 text-white dark:bg-black dark:text-blackText min-h-screen">
      <App />
    </div>
  </React.StrictMode>
);
