import React, { useState } from "react";
import ApiConnect from "../Utils/ApiConnect";
import { setUserSession } from "../Utils/Common";
import { useLocation } from "react-router";
import Ads from "../Component/Ads";
import PrivacyLinks from "../Component/PrivacyLinks";
import AnimatedPage from "../Component/AnimatedPage";
const Signup = (props) => {
  const search = useLocation().search;
  const macurl = new URLSearchParams(search).get("mac");

  const [data, setData] = useState({
    contact: sessionStorage.getItem("userid"),
    fullname: "",
    year: "",
    month: "",
    day: "",
    date_type: "BS",
    mac: macurl,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  function handleSubmit(e) {
    e.preventDefault();
    setError(null);
    setLoading(true);
    var formData = new FormData();
    formData.append("contact", data.contact);
    formData.append("fullname", data.fullname);
    formData.append("year", data.year);
    formData.append("month", data.month);
    formData.append("day", data.day);
    formData.append("date_type", data.date_type);
    formData.append("mac", data.mac);
    ApiConnect({
      method: "post",
      url: "Auth/HotspotSingup",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        if (response.data.status === 200) {
          console.log("already created login");
          setUserSession(response.data.token, response.data.id);
          window.location.href = "/login";
        }
        window.location.href = "/OTPVerification";
      })
      .catch((error) => {
        setLoading(false);
        setError("Something Went Wrong, please Try Again.");
        console.log(error);
      });
  }

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.name] = e.target.value;
    setData(newdata);
  }
  var day = [];
  var year = [];

  for (var i = 0; i < 32; i++) {
    day.push(<option value={i + 1}>{i + 1}</option>);
  }
  const current = new Date();
  for (var i = 0; i < 47; i++) {
    year.push(
      <option key={i} value={current.getFullYear() + 42 - (i + 1)}>
        {current.getFullYear() + 42 - (i + 1)}
      </option>
    );
  }
  return (
    <AnimatedPage>
      {/* <div>
        <h6 className="text-left text-base font-bold tracking-tight text-black dark:text-blackText">
          Register new account
        </h6>
      </div> */}
      <form
        className="space-y-4"
        action="#"
        onSubmit={(e) => handleSubmit(e)}
        method="POST"
      >
        <div className="-space-y-px rounded-md shadow-sm">
          <div className="py-2">
            <label htmlFor="fullname">Full Name</label>
            <input
              id="fullname"
              name="fullname"
              type="text"
              autoComplete="Text"
              required
              className="drop-shadow-md relative block w-full rounded border border-gray-300  placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 "
              placeholder="Smith Singh"
              onChange={(e) => handle(e)}
            />
          </div>
          <div className="py-2 ">
            <label htmlFor="dob">Date of Birth (BS)</label>
            <div className="flex flex-row">
              <select
                className="basis-1/4 relative block w-full rounded border border-gray-300  placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
                name="day"
                placeholder="DD"
                required=""
                value={data.day}
                onChange={(e) => handle(e)}
              >
                <option value="">Day</option>
                {day}
              </select>
              <select
                className="basis-1/2 mr-2 ml-2 relative block w-full rounded border border-gray-300  placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
                name="month"
                placeholder="MM"
                required=""
                value={data.month}
                onChange={(e) => handle(e)}
              >
                <option value="">Month</option>
                <option value="01">Baishakh</option>
                <option value="02">Jestha</option>
                <option value="03">Asar</option>
                <option value="04">Shrawan</option>
                <option value="05">Bhadra</option>
                <option value="06">Aswin</option>
                <option value="07">Kartik</option>
                <option value="08">Mansir</option>
                <option value="09">Poush</option>
                <option value="10">Magh</option>
                <option value="11">Falgun</option>
                <option value="12">Chaitra</option>
              </select>
              <select
                className="basis-1/4 relative block w-full rounded border border-gray-300  placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
                name="year"
                placeholder="YYYY"
                required=""
                value={data.year}
                onChange={(e) => handle(e)}
              >
                <option value="">Year</option>
                {year}
              </select>
            </div>
          </div>
          <div className="py-2">
            <label htmlFor="contact">Contact No</label>
            <input
              name="contact"
              type="number"
              autoComplete="number"
              value={data.contact}
              required
              pattern="[0-9]{10}"
              className="relative block w-full rounded border border-gray-300  placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 "
              placeholder="Register Contact Or Voucher No"
              onChange={(e) => handle(e)}
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            className=" submitButton group relative flex w-full justify-center rounded-md border border-transparent bg-primary py-3 px-4 text-sm font-medium text-white  dark:border-0  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              {/* Heroicon name: mini/lock-closed */}

              <span className="material-icons-outlined">input</span>
            </span>
            {loading ? "Loading...." : "Register"}
          </button>
          <PrivacyLinks />
        </div>
      </form>

    </AnimatedPage>
  );
};

export default Signup;
