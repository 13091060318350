import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import ViewAdsPop from "../Component/ViewAdsPop";
import moment from "moment";
import ApiConnect from "../Utils/ApiConnect";
import AnimatedPage from "../Component/AnimatedPage";
export default function Status() {
  const search = useLocation().search;
  const logoutLink = new URLSearchParams(search).get("link-logout");
  const bytesIn = new URLSearchParams(search).get("bytes-in-nice");
  const bytesOut = new URLSearchParams(search).get("bytes-out-nice");
  const sessionLeft = GetFormated(
    new URLSearchParams(search).get("session-time-left")
  );
  const upTime = GetFormated(new URLSearchParams(search).get("uptime"));
  const refreshTimeout = new URLSearchParams(search).get("refresh-timeout");
  const LinkStatus = new URLSearchParams(search).get("link-status");
  const error = new URLSearchParams(search).get("error");
  const ip = new URLSearchParams(search).get("ip");
  const linkAdvert = new URLSearchParams(search).get("link-advert");
  const blocked = new URLSearchParams(search).get("blocked");
  const Status = new URLSearchParams(search).get("Status");
  const macurl = new URLSearchParams(search).get("mac");
  useEffect(() => {
    ApiConnect.get(
      `/Hotspot/userData?mac=${macurl}&username=${sessionStorage.getItem(
        "userid"
      )}`
    ).then((response) => {
      const res = response.data;
      if (res.status !== 404) {
        sessionStorage.setItem("name", res.name);
      }
    });
  }, []);
  function GetFormated(data) {
    var time = data; // change this to your time string
    if (!time.toUpperCase().includes("H")) {
      time = "00h".concat(time);
    }
    var parts = time.split(/[h, m, s]/); // split by "h" to get hours and minutes
    var hours = parseInt(parts[0]); // parse the first part as hours
    var minutes = parseInt(parts[1]); // parse the second part as minutes and remove the "m"
    var seconds = parseInt(parts[2]) || 0; // assume seconds are zero
    const formatTime = hours + ":" + minutes + ":" + seconds; // print the result
    return formatTime;
  }
  function calculateWidth() {
    const totalMin = moment
      .duration(upTime, "HH:mm:ss")
      .add(sessionLeft, "HH:mm:ss")
      .asMinutes();
    const consume = moment.duration(upTime, "HH:mm:ss").asMinutes();
    return parseFloat((consume * 100) / totalMin);
  }
  return (
    <AnimatedPage>
      <div className=" p-2 bg-primary-300 rounded-lg mb-8">
        <div className="grid grid-cols-1 divide-y divide-y-2 divide-primary-200 space-y-2">
          <div className=" ">
            <h2 className="text-lg">Internet Usage</h2>
            <div className="flex items-center space-x-2 ">
              <span className="flex bg-primary-600 rounded-full w-14 h-14 items-center justify-center">
                <span className="material-icons-outlined ">language</span>
              </span>
              <div className="flex-auto">
                <div className="flex justify-between text-sm leading-6 font-medium tabular-nums">
                  <small>Usage</small>
                  <small>
                    {upTime}/
                    {moment(upTime, "HH:mm:ss")
                      .add(sessionLeft, "HH:mm:ss")
                      .add("00:01:00", "HH:mm:ss")
                      .format("H")}
                    hrs
                  </small>
                </div>
                <div className="h-2 w-full bg-primary-200 dark:bg-primary-600 rounded-lg">
                  <div
                    className="h-2 bg-secondary rounded-lg"
                    style={{ width: `${calculateWidth()}%` }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between py-2">
            <div className="flex space-x-1">
              <span className="material-icons-outlined text-2xl">lan</span>
              <p>{ip}</p>
            </div>
            <div className="flex  space-x-1">
              {blocked === "yes" ? (
                <>
                  <span className="material-icons-outlined text-2xl">
                    view_in_ar
                  </span>
                  <ViewAdsPop Adlinks={linkAdvert} view={true} />
                </>
              ) : (
                <>
                  <span className="material-icons-outlined text-2xl">link</span>
                  <p>Connected</p>
                </>
              )}
              {/* <span className="material-icons-outlined text-2xl">link_off</span>
              <p>No Internet</p> */}
            </div>
          </div>
          <div className="grid grid-cols-2 divide-x divide-x-2 divide-primary-200 text-center p-2">
            <div className="items-center">
              <div className="flex items-center justify-center">
                <span className="material-icons-outlined">download</span>
                <p>Download</p>
              </div>
              <h2 className="text-lg pt-2">{bytesIn} </h2>
            </div>
            <div className="items-center justify-center">
              <div className="flex items-center justify-center">
                <span className="material-icons-outlined">upload</span>
                <p>Upload</p>
              </div>
              <h2 className="text-lg pt-2">{bytesOut} </h2>
            </div>
          </div>
        </div>
      </div>
      <a
        href={logoutLink}
        className=" submitButton group relative flex w-full justify-center rounded-lg border border-transparent bg-primary py-3 px-4 text-sm font-medium text-white  dark:border-0  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
          {/* Heroicon name: mini/lock-closed */}
          <span className="material-icons-outlined">logout</span>
        </span>
        Logout
      </a>
    </AnimatedPage>
  );
}
