import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ReviveAd from "./ReviveAd";
export default function Ads() {
  const [isOpen, setIsOpen] = useState(true);
  const [countDown, setCountDown] = useState(5);
  const viewAds = sessionStorage.getItem("ads") || false

  const closeModal = () => {
    if (countDown === 0) {
      setIsOpen(false);
      sessionStorage.setItem("ads", true);
    }
  }

  useEffect(() => {
    //Implementing the setInterval method 
    if (viewAds) {
      setCountDown(0)
      closeModal()
    }
    const interval = setInterval(() => {
      if (countDown !== 0) {
        setCountDown(countDown - 1);
      }
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [countDown]);
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full justify-center items-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-w-full transform overflow-hidden p-1 transition-all ">
                  <div className="absolute w-full flex justify-between px-2 ">
                    <small className="text-white">Advertisement</small>
                    <button
                      className="rounded-lg bg-black/70 text-white p-1 flex"
                      onClick={() => closeModal()}
                    >
                      {countDown === 0 ? (
                        <>
                          Skip Ads
                          <span className="material-icons-outlined">
                            skip_next
                          </span>
                        </>
                      ) : (
                        <>Skip in {countDown}s</>
                      )}
                    </button>
                  </div>
                  <div className="mt-5">
                    <ReviveAd width={720} />
                    {/* 
                    <GoogleAd /> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
