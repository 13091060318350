import React, { Component } from "react";

export default class Facebook extends Component {
  render() {
    return (
      <button className="submitButton flex items-center">
        <img
          src="https://cdn-icons-png.flaticon.com/512/145/145802.png "
          alt="Facebook"
          title="Facebook"
          className="img-small"
          width="30"
          height="30"
        />
        <p className="pl-3 text-base">Facebook</p>
      </button>
    );
  }
}
