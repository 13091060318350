import React from "react";
import { NavLink as Link } from "react-router-dom";
export default function PrivacyLinks() {
  const company = "Narayani Bakery Cafe";
  return (
    <p className="text-xs text-center mt-5">
      By clicking Login/Register, you agree to
      <b className="text-xs">
        {" "}
        {company},<Link to="/PrivacyPolicy"> Data Policy</Link>
      </b>{" "}
      and
      <b className="text-xs">
        <Link to="/PrivacyPolicy"> Cookies Policy</Link>
      </b>
    </p>
  );
}
