import React from "react";

export default function Footer() {
  return (
    <footer
      className="col-12 align-self-end text-center fs-13 fixed
             inset-x-0
             bottom-0"
    >
      <p>
        Copyright © 2023{" "}
        <a href="https://narayaniconcept.com">Narayani Group</a>. All rights
        reserved.
      </p>
    </footer>
  );
}
