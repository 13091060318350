import React, { Component } from "react";
import { v4 as uuidv4 } from 'uuid';
export default class ReviveAd extends Component {
    constructor(props) {
        super(props);
        this.state = { random: uuidv4() };
    }
    render() {
        const random = this.state.random
        const serverUrl = process.env.REACT_APP_REVIVE_URL
        const adZone = process.env.REACT_APP_REVIVE_ZONE
        const adImage = 'https://' + serverUrl + '/www/delivery/avw.php?zoneid=' + adZone + '&amp;cb=' + random + '&amp;n=a74dc997'
        const adLink = 'https://' + serverUrl + '/www/delivery/ck.php?n=a74dc997&amp;cb=asdasd' + random
        return (
            <div className="pt-1">
                <img
                    alt="banner"
                    width={this.props.width || '100'}
                    src={adImage}
                />
            </div >
        );
    }
} 
