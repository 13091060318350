import React from "react";
import "../assets/css/loading.css";
import logo from "../assets/logo.svg";
import AnimatedLogo from "./AnimateLogo";
export default function NgLoading() {
  const Loading = false;
  return (
    <div className="preloader" style={{ display: `${Loading ? "" : "none"}` }}>
      <div className="spinner-dots">
        {/* <img src={logo} className="pb-10" alt="NG" /> */}
        <AnimatedLogo />
        <span className="dot1"></span>
        <span className="dot2"></span>
        <span className="dot3"></span>
      </div>
    </div>
  );
}
