import React, { useEffect, useState } from "react";
import Ads from "../Component/Ads";
import PrivacyLinks from "../Component/PrivacyLinks";
import { NavLink as Link } from "react-router-dom";
import SocialLogin from "../Component/SocialLogin";
import { setUserSession } from "../Utils/Common";
import { useLocation } from "react-router-dom";
import ApiConnect from "../Utils/ApiConnect";
import NetworkError from "../Component/NetworkError";
import AnimatedPage from "../Component/AnimatedPage";
export default function Login() {
  //  mac=3C%3AA6%3AF6%3A43%3AA3%3AAB&ip=10.10.30.104&username=&link-login=https%3A%2F%2Fwifi.narayanibakery.com.np%2Flogin%3Fdst%3Dhttp%253A%252F%252Fdetectportal.firefox.com%252Fcanonical.html&link-orig=http%3A%2F%2Fdetectportal.firefox.com%2Fcanonical.html&error=&trial=yes&chap-id=&chap-challenge=&link-login-only=https%3A%2F%2Fwifi.narayanibakery.com.np%2Flogin&link-orig-esc=http%253A%252F%252Fdetectportal.firefox.com%252Fcanonical.html&mac-esc=3C%253AA6%253AF6%253A43%253AA3%253AAB&identity=Main_Borad&bytes-in-nice=0+B&bytes-out-nice=0+B&session-time-left=&uptime=0s&refresh-timeout=&link-status=https%3A%2F%2Fwifi.narayanibakery.com.np%2Fstatus&login=login
  const search = useLocation().search;
  const macurl = new URLSearchParams(search).get("mac");
  const linkLoginOnly = new URLSearchParams(search).get("link-login-only");
  const linkOrigEsc = new URLSearchParams(search).get("link-orig-esc");
  const macEsc = new URLSearchParams(search).get("mac-esc");
  const DeviceError = new URLSearchParams(search).get("error");
  const [data, setData] = useState({
    username: "",
    mac: macurl,
  });
  const [error, setError] = useState(DeviceError || null);
  const [loading, setLoading] = useState(null);
  const [loginBtn, setLoginBtn] = useState("disabled");
  const [showModal, setshomodal] = useState(false);
  const [showUserdata, setShowUserData] = useState(false);
  const triallink = `${linkLoginOnly}?dst=${linkOrigEsc}&username=T-${macEsc}`;
  sessionStorage.setItem("trialLink", triallink);
  sessionStorage.setItem("usermac", macurl || false);
  const blocked = new URLSearchParams(search).get("blocked");
  const Status = new URLSearchParams(search).get("Status");

  useEffect(() => {
    ApiConnect.get(
      `/Hotspot/userData?mac=${macurl}&username=${sessionStorage.getItem(
        "userid"
      )}`
    ).then((response) => {
      const res = response.data;
      if (res.status !== 404) {
        sessionStorage.setItem("name", res.name);
        sessionStorage.setItem("userid", res.id);
        // newdata["username"] = res.id;
        // setData(newdata); 
        setShowUserData(true);
      } else {
        setShowUserData(false);
      }
    });
  }, []);

  function handleSubmit(e) {
    const newdata = { ...data };
    newdata[e.target.name] = e.target.value;
    setData(newdata);
    if (e.target.value.length === 10) {
      if (sessionStorage.usermac === "false") {
        setshomodal(true);
      } else {
        e.preventDefault();
        setError(null);
        setLoading(true);
        var formData = new FormData();
        formData.append("username", e.target.value);
        formData.append("mac", data.mac);
        ApiConnect({
          method: "post",
          url: "/Auth/HotspotLogin",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            setLoading(false);
            sessionStorage.setItem("userid", e.target.value);
            if (response.data.status === 200) {
              if (data.mac) {
                setLoginBtn(false);
              } else {
                window.location.href = "/Status";
              }
              setUserSession(
                response.data.token,
                response.data.id,
                response.data.Name
              );
            } else if (response.data.status === 204) {
              window.location.href = "/OTPVerification";
            } else if (response.data.status === 404) {
              window.location.href = "/Signup";
            } else {
              setError(response.data.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            setError(error.message);
          });
      }
    }
  }

  useEffect(() => {
    if (sessionStorage.usermac !== "false") {
      console.log("true", sessionStorage.usermac);
    }
  }, []);
  return (
    <AnimatedPage>
      <Ads />
      <NetworkError view={showModal} />
      {/* <h6 className=" text-left text-base font-bold tracking-tight text-black dark:text-blackText">
          Sign in to your account
        </h6> */}
      <div className="space-y-4">
        <div className=" rounded-lg shadow-sm">
          <p className="error">{error}</p>

          <label htmlFor="contact" className="p-1">
            {showUserdata
              ? "Welcome back " + sessionStorage.getItem("name")
              : ""}
          </label>
          <input
            name="username"
            type="number"
            autoComplete="number"
            required
            className="relative block w-full rounded-lg border bg-transparent border-gray-300  placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 "
            placeholder={
              showUserdata
                ? sessionStorage.getItem("userid")
                : `Register Contact Or Voucher No`
            }
            onChange={(e) => handleSubmit(e)}
            value={data.username}
            maxLength="10"
          />
        </div>

        <div>
          {loginBtn ? (
            <button
              href={loginBtn ? "/" : triallink}
              disabled={loginBtn}
              className=" submitButton group relative flex w-full justify-center rounded-lg border border-transparent bg-primary py-3 px-4 text-sm font-medium text-white  dark:border-0  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                {/* Heroicon name: mini/lock-closed */}
                <span className="material-icons-outlined">login</span>
              </span>
              {loading ? "Loading...." : "Login"}
            </button>
          ) : (
            <a
              href={loginBtn ? "/" : triallink}
              disabled={loginBtn}
              className=" submitButton group relative flex w-full justify-center rounded-lg border border-transparent bg-primary py-3 px-4 text-sm font-medium text-white  dark:border-0  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                {/* Heroicon name: mini/lock-closed */}
                <span className="material-icons-outlined">login</span>
              </span>
              {loading ? "Loading...." : "Login"}
            </a>
          )}
        </div>
      </div>
      <br />
      {/* <SocialLogin /> */}
      <PrivacyLinks />
      <br />
      <p className="text-center">
        Don't have Account ?
        <b>
          <Link to="/Signup">Signup</Link>
        </b>
      </p>
    </AnimatedPage>
  );
}
