import axios from "axios";
import { getToken, getUserId } from "./Common";

let headers = {
  Authorization: process.env.REACT_APP_API_KEY,
  Token: getToken(),
  "User-ID": sessionStorage.getItem("userid"),
  Company: process.env.REACT_APP_COMPANY,
};

export default axios.create({
  baseURL: `https://${process.env.REACT_APP_API_URL}/`,
  responseType: "json",
  headers,
});
