import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

export default function NetworkError(props) {
  let [isOpen, setIsOpen] = useState(props.view);
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <Transition appear show={isOpen || props.view} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white dark:bg-black p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium ">
                    Bad Request - Invalid URL
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm my-5">
                      Looks like you've redirected to or entered a URL that
                      doesn't contains required information to proceed.
                    </p>
                    <hr />
                    <p className="my-5">
                      If this page is appearing even after retry, please contact
                      our support for further assistant.
                    </p>
                  </div>

                  <div className="mt-4">
                    <a
                      href="http://www.msftconnecttest.com/redirect"
                      className="submitButton"
                      onClick={closeModal}
                    >
                      Try Again
                    </a>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
